import React from 'react'
import styled from 'styled-components'


interface Iprops{
    postsPerPage: number,
    totalPosts: number
    paginate: any
}

const Pagination: React.FC<Iprops> = ({
    postsPerPage, totalPosts, paginate
}) => {

    const PageNumbers: number[] = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage) ; i++) {
        PageNumbers.push(i)
    }


  return (
    <div>
       <Container>
       {
            PageNumbers.map(number =>(
                
                    <a
                    onClick={() => paginate(number)}
                    href="#">
                        <Button key={number}>
                            {number}
                        </Button>
                    
                    </a>
            ))
        }
       </Container>
    </div>
  )
}

export default Pagination;

const Container = styled.div`
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Button = styled.button`
    width: 50px;
    height: 50px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    background-color: #BB3D6E;
    cursor: pointer;
    transition: all 350ms;
    color: white;
    a{
        color: white;
        text-decoration: none;
    }
    :hover{
        transform: scale(1.1);
        background-color: #df779f;
        border-radius: 10px;
    }
`;